<template>
  <div id="mainPage">
    <div class="topBC">
      <!--顶部背景图片-->
      <img src="../static/images/logo_memberShip.png" width="110px" height="45px"/>
    </div>
    <div class="title">
      <span v-if="pageType == 1">场馆地图</span>
      <span v-if="pageType == 2">测评须知</span>
      <img src="../static/images/logo_goAllOutMan.png" width="69px" height="86px"/>
      <em class="button" @click="onGoNewPage('testMemberNavi')">主页</em>
    </div>

    <!--预约结果-->
    <div class="context" v-if="pageType == 2" style="margin-top: 90px;">
      <!--预约状态-->
      <div class="appointStatus" v-if="orderShop" style="margin-bottom: 30px">
        <div class="contentTitle">
          <span>预约成功</span>
        </div>
        <div class="contentLine">
          <span>测试时间:</span>
          <em>{{ orderTime }}</em>
        </div>
        <!--训练场馆-->
        <div class="contentLine" @click="pageType = 1">
          <span>训练场馆:</span>
          <em>{{ orderShop }}</em>
        </div>
        <!--商家地址-->
        <div class="contentLine">
          <span>商家地址:</span>
          <em>{{ orderAddress }}</em>
        </div>
        <!--距离测评-->
        <div class="contentTime">
          <div class="timeText">
            <span>距离测评:</span>
            <em>{{ timeBetWeen }}</em>
          </div>
          <div :class="[{'disableButton':cancelDisable(orderTime)},{'cancelButton':!cancelDisable(orderTime)}]"
               @click="cancelAppointment()">
            取消预约
          </div>
        </div>
      </div>

      <!--测试须知-->
      <div class="testNeedsKnown">
        <img src="../static/images/pic_testNeedsKnow.png"/>
        <span>联系电话:156 8940 9077 (董老师)</span>
      </div>
    </div>

    <!--英泓小飞龙蒙正店-->
    <div class="context" v-if="pageType == 1" style="margin-top: 90px">
      <!--预约状态-->
      <div class="addressDetail">
        <div class="contentTitle">
          <span>英泓小飞龙蒙正店</span>
        </div>
        <div class="contentLine">
          <span>地址:</span>
          <em>济南市云翔路515号负一层</em>
        </div>
        <!--训练场馆电话-->
        <div class="contentLine">
          <span>电话:</span>
          <em>156 8940 9077 (董老师)</em>
        </div>
        <!--场馆地图-->
        <div class="picLine">
          <img src="../static/images/pic_map_shopMengZheng.png"/>
        </div>
      </div>

      <!--预约状态-->
      <div class="addressDetail">
        <div class="contentTitle">
          <span>英泓小飞龙奥龙店</span>
        </div>
        <div class="contentLine">
          <span>地址:</span>
          <em>济南市东荷路888号（奥龙学校对面）</em>
        </div>
        <!--训练场馆电话-->
        <div class="contentLine">
          <span>电话:</span>
          <em>156 8940 9077 (董老师)</em>
        </div>
        <!--场馆地图-->
        <div class="picLine">
          <img src="../static/images/pic_map_shopAoLong.png"/>
        </div>
      </div>
      <!--预约状态-->
      <div class="addressDetail">
        <div class="contentTitle">
          <span>英泓小飞龙恒大帝景店</span>
        </div>
        <div class="contentLine">
          <span>地址:</span>
          <em>济南市文化东路63号恒大帝景五号楼底商3-204</em>
        </div>
        <!--训练场馆电话-->
        <div class="contentLine">
          <span>电话:</span>
          <em>156 8940 9077 (董老师)</em>
        </div>
        <!--场馆地图-->
        <div class="picLine">
          <img src="../static/images/pic_map_shopHengDa.png"/>
        </div>
      </div>
    </div>

    <alert ref="alertPart"></alert>
  </div>
</template>

<script>
import {
  MyOrderFitnessTestCancel,
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

let qs = require('qs');

export default {
  data() {
    return {
      pageType: 1,//1:商家地图    2:预约结果和注意事项
      oftId: '2',
      phone: '2',
      orderTime: '2022-09-09 12:22',
      orderShop: '333',
      orderAddress: '444',
      timeBetWeen: ''
    }
  },
  mounted() {
    if (null != this.$route.query.pageType) {
      this.pageType = this.$route.query.pageType;
      if (this.pageType == 2) {
        this.oftId = this.$route.query.oftId;
        this.phone = this.$route.query.phone;
        this.orderTime = this.$route.query.orderTime;
        this.orderShop = this.$route.query.orderShop;
        this.orderAddress = this.$route.query.orderAddress;
        this.timeBetWeen = this.getTimeBetweenStr(this.orderTime);
      }
    }
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'testMembershipAddress') {
        if (null != this.$route.query.pageType) {
          this.pageType = this.$route.query.pageType;
          if (this.pageType == 2) {
            this.oftId = this.$route.query.oftId;
            this.phone = this.$route.query.phone;
            this.orderTime = this.$route.query.orderTime;
            this.orderShop = this.$route.query.orderShop;
            this.orderAddress = this.$route.query.orderAddress;
            this.timeBetWeen = this.getTimeBetweenStr(this.orderTime);
          }
        }
      }
    },
    'active'() {
    }
  },
  methods: {
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    onGoNewPage(path) {
      this.$router.push({path: '/' + path});
    },
    goPage(pages) {
      this.$router.push({path: pages});
    },
    //取消预约
    cancelAppointment() {
      let that = this;
      if (that.cancelDisable(that.orderTime)) {
        return;
      }
      this.$confirm('确定要取消预约吗？', '提示', {
        type: 'warning'
      }).then(({result}) => {
        if (result) {
          let param = {
            oftId: that.oftId,
            phone: that.phone
          };
          let postdata = qs.stringify(param);
          MyOrderFitnessTestCancel(postdata).then(res => {
            let json = res;
            if (json.Code == 0) {
              this.$confirm('取消成功').then(({result}) => {
                this.$router.push({path: '/testMemberNavi'});
              });
            } else {
              that.alertInfo(json.Memo);
            }
          })
        }
      });
    },
    cancelDisable(orderTime) {
      let that = this;
      let disable = that.getTimeDiffGetArr(orderTime)[0] < 1 && that.getTimeDiffGetArr(orderTime)[1] < 1 && that.getTimeDiffGetArr(orderTime)[2] < 61;
      return disable;
    },
    getTimeDiffGetArr(endDate) {
      return timeDiffGetArr(timeNow(),endDate);
    },
    getTimeBetweenStr(endDate) {
      let diffArr = timeDiffGetArr(timeNow(),endDate);
      let diffStr = '';
      if (diffArr[0] > 0) {
        diffStr += diffArr[0] + ' 天 ';
      }
      if (diffArr[1] > 0) {
        diffStr += diffArr[1] + ' 小时 ';
      }
      if (diffArr[2] > 0) {
        diffStr += diffArr[2] + ' 分 ';
      }
      return diffStr;
    },
  },
  beforeDestroy() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
      }
    });
  },
  components: {
    bottomTab, alert, DatePicker
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding-bottom: 50px;
  background: url('../static/images/bc_memberShip.png') top center / cover;
  overflow-y: scroll;
}

.topBC {
  height: 80px;
  width: 100%;
  text-align: center;
}

.topBC img {
  margin-top: 21px;
}

.title span {
  display: block;
  font-size: 28px;
  text-align: center;
  color: white;
}

.title img {
  float: right;
  margin-top: -50px;
  margin-right: 25px;
}

.title .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -100px;
  border-radius: 3px;
}

.context {
  width: 95%;
  margin-left: 2.5%;
  border-radius: 10px;
}

.title span {
  display: block;
  font-size: 36px;
  color: white;
  text-align: center;
}

.context .appointStatus {
  backdrop-filter: blur(5px);
}

.appointStatus .contentTime {
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
}

.appointStatus .contentTime .timeText {
  float: left;
  margin-right: 5px;
  font-size: 14px;
}

.appointStatus .contentTime .timeText span {
  margin-right: 12px;
}

.appointStatus .contentTime .cancelButton {
  float: left;
  font-size: 14px;
  margin-left: 5px;
  color: #FF5800;
}

.appointStatus .disableButton {
  float: left;
  font-size: 14px;
  margin-left: 5px;
  color: #CECECE;
}

.testNeedsKnown img {
  width: 95%;
  margin-left: 2.5%;
}

.testNeedsKnown span {
  font-size: 16px;
  text-align: center;
  display: block;
  color: #4A4A4A;
  margin: -40px auto 0;
}

.context .appointStatus {
  width: 95%;
  background: rgba(255, 255, 255, 0.56);
  margin: 0 auto;
  padding-bottom: 20px;
  padding-left: 39px;
  padding-right: 39px;
  border-radius: 15px;
}

.appointStatus .contentTitle {
  height: 70px;
  line-height: 70px;
  font-size: 22px;
  color: #363636;
  margin-top: 35px;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}

.appointStatus .contentLine {
  margin-top: 5px;
}

.appointStatus .contentLine span {
  font-size: 14px;
}

.appointStatus .contentLine em {
  font-size: 14px;
  margin-left: 12px;
}

.appointStatus .picLine img {
  width: 90%;
  height: 212px;
  margin-top: 5px;
  margin-left: 5%;
}

.context .addressDetail {
  width: 98%;
  background: rgba(255, 255, 255, 0.56);
  margin: 0 auto;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 15px;
}

.addressDetail .contentTitle {
  height: 70px;
  line-height: 70px;
  font-size: 22px;
  color: #363636;
  margin-top: 35px;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}

.addressDetail .contentLine {
  margin-top: 5px;
}

.addressDetail .contentLine span {
  font-size: 14px;
}

.addressDetail .contentLine em {
  font-size: 14px;
  margin-left: 12px;
}

.addressDetail .picLine img {
  width: 100%;
  height: 212px;
  margin-top: 5px;
}

/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
