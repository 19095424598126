var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mainPage"}},[_vm._m(0),_c('div',{staticClass:"title"},[(_vm.pageType == 1)?_c('span',[_vm._v("场馆地图")]):_vm._e(),(_vm.pageType == 2)?_c('span',[_vm._v("测评须知")]):_vm._e(),_c('img',{attrs:{"src":require("../static/images/logo_goAllOutMan.png"),"width":"69px","height":"86px"}}),_c('em',{staticClass:"button",on:{"click":function($event){return _vm.onGoNewPage('testMemberNavi')}}},[_vm._v("主页")])]),(_vm.pageType == 2)?_c('div',{staticClass:"context",staticStyle:{"margin-top":"90px"}},[(_vm.orderShop)?_c('div',{staticClass:"appointStatus",staticStyle:{"margin-bottom":"30px"}},[_vm._m(1),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("测试时间:")]),_c('em',[_vm._v(_vm._s(_vm.orderTime))])]),_c('div',{staticClass:"contentLine",on:{"click":function($event){_vm.pageType = 1}}},[_c('span',[_vm._v("训练场馆:")]),_c('em',[_vm._v(_vm._s(_vm.orderShop))])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("商家地址:")]),_c('em',[_vm._v(_vm._s(_vm.orderAddress))])]),_c('div',{staticClass:"contentTime"},[_c('div',{staticClass:"timeText"},[_c('span',[_vm._v("距离测评:")]),_c('em',[_vm._v(_vm._s(_vm.timeBetWeen))])]),_c('div',{class:[{'disableButton':_vm.cancelDisable(_vm.orderTime)},{'cancelButton':!_vm.cancelDisable(_vm.orderTime)}],on:{"click":function($event){return _vm.cancelAppointment()}}},[_vm._v(" 取消预约 ")])])]):_vm._e(),_vm._m(2)]):_vm._e(),(_vm.pageType == 1)?_c('div',{staticClass:"context",staticStyle:{"margin-top":"90px"}},[_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e(),_c('alert',{ref:"alertPart"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topBC"},[_c('img',{attrs:{"src":require("../static/images/logo_memberShip.png"),"width":"110px","height":"45px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentTitle"},[_c('span',[_vm._v("预约成功")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testNeedsKnown"},[_c('img',{attrs:{"src":require("../static/images/pic_testNeedsKnow.png")}}),_c('span',[_vm._v("联系电话:156 8940 9077 (董老师)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addressDetail"},[_c('div',{staticClass:"contentTitle"},[_c('span',[_vm._v("英泓小飞龙蒙正店")])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("地址:")]),_c('em',[_vm._v("济南市云翔路515号负一层")])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("电话:")]),_c('em',[_vm._v("156 8940 9077 (董老师)")])]),_c('div',{staticClass:"picLine"},[_c('img',{attrs:{"src":require("../static/images/pic_map_shopMengZheng.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addressDetail"},[_c('div',{staticClass:"contentTitle"},[_c('span',[_vm._v("英泓小飞龙奥龙店")])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("地址:")]),_c('em',[_vm._v("济南市东荷路888号（奥龙学校对面）")])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("电话:")]),_c('em',[_vm._v("156 8940 9077 (董老师)")])]),_c('div',{staticClass:"picLine"},[_c('img',{attrs:{"src":require("../static/images/pic_map_shopAoLong.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addressDetail"},[_c('div',{staticClass:"contentTitle"},[_c('span',[_vm._v("英泓小飞龙恒大帝景店")])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("地址:")]),_c('em',[_vm._v("济南市文化东路63号恒大帝景五号楼底商3-204")])]),_c('div',{staticClass:"contentLine"},[_c('span',[_vm._v("电话:")]),_c('em',[_vm._v("156 8940 9077 (董老师)")])]),_c('div',{staticClass:"picLine"},[_c('img',{attrs:{"src":require("../static/images/pic_map_shopHengDa.png")}})])])
}]

export { render, staticRenderFns }